import React, { useEffect, useState } from "react";
// import CreateBookingEditableInput from "../CreateBookingEditableInput/CreateBookingEditableInput";
import CreateBookingEditableInput from "../../CreateBookingNewPage/CreateBookingEditableInput/CreateBookingEditableInput";
// import CreateBookingInputBox from "../CreateBookingInputBox/CreateBookingInputBox";
import CreateBookingInputBox from "../../CreateBookingNewPage/CreateBookingInputBox/CreateBookingInputBox";
import { useSelector } from "react-redux";
// import "./CreateBookingCard.css";
import "../../CreateBookingNewPage/createBookingCard/CreateBookingCard.css";
import { showErrorToast } from "../../../../assets/toastUtils";
import { getDecryptedData } from "../../../../utils/encryptStorage";

const AgentHoldRoomsCreateBookingCard = ({
  index,
  handleDeleteBookingCard,
  tax_type,
  discount_type,
  getAdhocArrayFromChild,
  singleBookingData,
  setBookingDataArray,
  // bookingDataArray
}) => {
  const [errors, setErrors] = useState({
    no_of_rooms: false,
    no_of_adults: false,
    extra_adults: false,
    no_of_child: false,
  });

  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);

  const [showAdHocRoomRate, setShowAdHocRoomRate] = useState(false);
  const [adHocRoom, setAdHocRoom] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [additionalBookingDetails, setAdditionalBookingDetails] = useState("");

  useEffect(() => {
    if (singleBookingData.room_rate_details) {
      setAdditionalBookingDetails(singleBookingData.room_rate_details);
    }
  }, [singleBookingData, setAdditionalBookingDetails]);

  useEffect(() => {
    if (singleBookingData && singleBookingData.ad_hoc_rate !== undefined) {
      setAdHocRoom(singleBookingData.ad_hoc_rate);
    }
  }, [singleBookingData, setAdHocRoom]);

  useEffect(() => {
    setBookingDataArray((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        tax_type: tax_type,
      };
      return updatedData;
    });
  }, [tax_type, index]);

  //===== HELPER FUNCTION FOR SETTING ERRORS  WITH TIMEOUT TO DISAPPEAR=====

  const setErrorWithTimeout = (errorField, duration = 3000) => {
    setErrors((prev) => ({ ...prev, [errorField]: true }));
    setTimeout(() => {
      setErrors((prev) => ({ ...prev, [errorField]: false }));
    }, duration);
  };
  //===========================================

  // ====== input handlers  functions ======

  const handleRoomPlanChange = async (e, index) => {
    const selectedMealPlanId = e.target.value;
    if (!selectedMealPlanId) {
      return;
    }
    try {
      const payload = {
        check_in: singleBookingData.check_in,
        check_out: singleBookingData.check_out,
        room_id: singleBookingData.room_id,
        mealplan_id: selectedMealPlanId,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/details/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      // Update the specific booking data item in the array
      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          mealplan_id: selectedMealPlanId,
          room_rate_details: data.rate,
          // discount_type: {
          //   ...updatedData[index].discount_type,
          //   discount_amount: 0,
          // },
        };
        return updatedData;
      });

      setAdditionalBookingDetails(data?.rate);
    } catch (error) {
      console.error(error);
      showErrorToast(
        "An error occurred while fetching room rates. Please try again."
      );
    }
  };
  const handleShowAdhocRates = (index) => {
    if (showAdHocRoomRate) {
      setShowAdHocRoomRate(false);
      setAdHocRoom("");

      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          ad_hoc_rate: "",
          discount_type: discount_type,
        };
        return updatedData;
      });

      getAdhocArrayFromChild(false, index);
    } else {
      setShowAdHocRoomRate(true);
      getAdhocArrayFromChild(true, index);

      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          discount_type: {
            ...prevData[index].discount_type,
            discount_amount: 0,
          },
        };
        return updatedData;
      });
    }
  };

  const handleAdoRatePerNightChange = (e, index) => {
    const value = e.target.value;
    setBookingDataArray((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        ad_hoc_rate: value,
      };
      return updatedData;
    });

    setAdHocRoom(value);
  };

  useEffect(() => {
    if (!singleBookingData || !singleBookingData.room_rate_details) {
      return;
    }

    const roomRateNew = adHocRoom
      ? adHocRoom
      : singleBookingData.room_rate_details.rate_bar_price;

   

    setBookingDataArray((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        room_rate_new: roomRateNew,
      };
      return updatedData;
    });
  }, [
    adHocRoom,
    index,
    setBookingDataArray,
    singleBookingData.room_rate_details,
  ]);

  const handleRoomsChange = (e, index) => {
    const { name, value } = e.target;

    if (value === "") {
      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          no_of_child: "",
          no_of_adults: "",
          extra_adults: "",
        };
        return updatedData;
      });
      return;
    }

    if (+value === 0) {
      showErrorToast("Please enter a valid number of adults");
      setErrorWithTimeout("no_of_rooms");
      return;
    }

    const numberOfRooms = +value;

    if (numberOfRooms > +singleBookingData?.limits?.available_rooms) {
      showErrorToast(
        `Maximum number of available rooms is ${singleBookingData?.limits?.available_rooms}`
      );
      setErrorWithTimeout("no_of_rooms");
      return;
    } else {
      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          no_of_child: "",
          no_of_adults: "",
          extra_adults: "",
        };
        return updatedData;
      });
      setErrors((prev) => ({ ...prev, no_of_rooms: false }));
    }
  };


  const handleNumberOfAdultsChange = (e, index) => {
    const { name, value } = e.target;
    const numberOfRooms = +singleBookingData.no_of_rooms;

    if (!numberOfRooms) {
      showErrorToast("Please enter number of rooms");
      return;
    }

    if (value === "") {
      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          extra_adults:"",
          no_of_child:""

        };
        return updatedData;
      });
      return;
    }

    if (+value === 0) {
      showErrorToast("Please enter a valid number of adults");
      setErrorWithTimeout("no_of_adults");
      return;
    }

    const maxAdultsPerRoom = singleBookingData?.limits?.base_adult;
    if (+value > +maxAdultsPerRoom * numberOfRooms) {
      showErrorToast("Maximum number of adults allowed reached");
      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: "",
          
        };
        return updatedData;
      });
      setErrorWithTimeout("no_of_adults");
    } else {
      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          extra_adults:"" ,
          no_of_child:""
        };
        return updatedData;
      });
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
    }
  };
  const handleNumberOfAdultsChangeFocus = (index) => {};

  // ============ extra adult ===============

  const [activeIndexAdults, setActiveIndexAdults] = useState(
    Array.from({ length: +singleBookingData.extra_adults }, () => false)
  );

  const [extraAdultData, setExtraAdultData] = useState([]);
  useEffect(() => {
    setExtraAdultData(
      Array.from({ length: +singleBookingData.extra_adults }, () => ({
        rate: "",
        ad_hoc_rate: "",
      }))
    );
  }, [singleBookingData.extra_adults]);

  const handleCommonExtraAdultChange = (e, index) => {
    const { name, value } = e.target;
    const perRoomExtraAdult = +singleBookingData.limits.allowedExtraAdult;
    const noOfRooms = +singleBookingData.no_of_rooms;

    if (!noOfRooms) {
      showErrorToast("Please enter number of rooms");
      return;
    }
    if (singleBookingData.no_of_child !== "") {
      const backendMaxOccupancy =
        singleBookingData?.limits?.max_adult * +singleBookingData.no_of_rooms;
      const frontEndMaxOccupancy =
        +singleBookingData.no_of_adults + +singleBookingData.no_of_child;
      const maxExtraAdultsAllowed =
        (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
        singleBookingData.no_of_rooms;
      if (+value > +maxExtraAdultsAllowed) {
        showErrorToast(
          "Max occupancy reached! please increase number of rooms"
        );
        setBookingDataArray((prevData) => {
          const updatedData = [...prevData];
          updatedData[index] = {
            ...updatedData[index],
            [name]: "",
          };
          return updatedData;
        });
        setErrorWithTimeout("extra_adults");
        return;
      }
    }

    if (+value > perRoomExtraAdult * noOfRooms) {
      showErrorToast("Max limit reached, please increase the number of rooms");
      setErrorWithTimeout("extra_adults");
    } else {
      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          no_of_child: "",
        };
        return updatedData;
      });

      setExtraAdultData(
        Array.from({ length: +value }, () => ({
          rate: additionalBookingDetails.extra_adult,
          ad_hoc_rate: "",
        }))
      );

      setActiveIndexAdults(Array.from({ length: +value }, () => false));
      setErrors((prev) => ({ ...prev, extra_adults: false }));
    }
  };
 

  const handleExtraAdultChange = (e, indexExtraAdult) => {
    const { name, value } = e.target;
    setExtraAdultData((prevData) => {
      const newData = [...prevData];
      newData[indexExtraAdult]["rate"] = additionalBookingDetails.extra_adult;
      newData[indexExtraAdult][name] = value;
      return newData;
    });
  };

  const handleShowAddHocAdult = (index) => {
    // Toggle the active state for the extra adult
    setActiveIndexAdults((prevActiveIndexAdults) => {
      const updatedActiveIndexAdults = [...prevActiveIndexAdults];
      updatedActiveIndexAdults[index] = !updatedActiveIndexAdults[index];
      return updatedActiveIndexAdults;
    });
  
    // Clear the `ad_hoc_rate` field for the corresponding index
    setExtraAdultData((prevData) => {
      const updatedExtraAdultData = [...prevData];
      if (!updatedExtraAdultData[index]) return updatedExtraAdultData;
  
      updatedExtraAdultData[index].ad_hoc_rate = ""; // Clear the field
      return updatedExtraAdultData;
    });
  };
  // ============ extra adult end  ==============

  // ================= child data change ====================

  const [childData, setChildData] = useState([]);
  useEffect(() => {
    setChildData(
      Array.from({ length: +singleBookingData?.no_of_child }, () => ({
        age: "",
        rate: "",
        ad_hoc_rate: "",
      }))
    );
  }, [singleBookingData?.no_of_child]);

  const [activeIndexChild, setActiveIndexChild] = useState(
    Array.from({ length: +singleBookingData?.no_of_child }, () => false)
  );
  const handleChildInputChange = (e, index) => {
    const { name, value } = e.target;

    const allowedChild = +singleBookingData.limits.max_child;
    const numberOfRooms = +singleBookingData.no_of_rooms;
    const numberOfChildren = +value;

    if (numberOfChildren > allowedChild * numberOfRooms) {
      showErrorToast("Value cannot exceed the available child limit");
      setErrorWithTimeout("no_of_child");
      return;
    }

    const backendMaxOccupancy =
      +singleBookingData.limits.max_adult * numberOfRooms;
    const frontendMaxOccupancy =
      +singleBookingData.no_of_adults + +singleBookingData.extra_adults;
    const maxChildAllowed =
      (backendMaxOccupancy - frontendMaxOccupancy + 1) * numberOfRooms;

    if (numberOfChildren > maxChildAllowed) {
      showErrorToast("Max occupancy reached! Please increase number of rooms");
      setErrorWithTimeout("no_of_child");
      return;
    }

    // Update booking data array
    setBookingDataArray((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [name]: value, // Use the `name` dynamically to update the specific field
      };
      return updatedData;
    });

    // Update child data
    setChildData(
      Array.from({ length: numberOfChildren }, () => ({
        age: "",
        rate: additionalBookingDetails.child_with_Bed,
        ad_hoc_rate: "",
      }))
    );
    setErrors((prev) => ({ ...prev, no_of_child: false }));
  };

  const handleChildAgeChange = (e, indexChild) => {
    const age = parseInt(e.target.value);

    setChildData((prevData) => {
      const newData = [...prevData];
      if (isNaN(age)) {
        newData[indexChild].age = "";
      } else if (age > 12) {
        newData[indexChild].age = "";

        showErrorToast("Age should be less than 12");
      } else if (age < 6) {
        newData[indexChild].age = age;
        newData[indexChild].rate = 0;
      } else {
        newData[indexChild].age = age;
        newData[indexChild].rate = additionalBookingDetails.child_with_Bed;
      }
      return newData;
    });
  };
  const handleShowAddHocChild = (indexChild) => {
    setActiveIndexChild((prevActiveIndexChild) => {
      const updatedActiveIndexChild = [...prevActiveIndexChild];
      updatedActiveIndexChild[indexChild] = !updatedActiveIndexChild[indexChild]; // Toggle the active state
      return updatedActiveIndexChild;
    });
    setChildData((prevData) => {
      const updatedChildData = [...prevData];
      if (!updatedChildData[indexChild]) return updatedChildData;
  
      updatedChildData[indexChild].ad_hoc_rate = ""; // Clear the field
      return updatedChildData;
    });
  };
  const handleChildAdHocRate = (e, index) => {
    const { name, value } = e.target;
    setChildData((prevData) => {
      const newData = [...prevData];
      newData[index][name] = value;
      return newData;
    });
  };
  const handleChildRadioButtons = (e, index) => {
    const { name, value } = e.target;
    setChildData((prevData) => {
      return prevData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            rate: value,
            extra_bed_occupied:
              value === additionalBookingDetails.child_with_Bed ? true : false,
          };
        }
        return item;
      });
    });
  };

  // ================= child data change end ====================

  // ===== Child Data , Extra Adult Data merge ========
  useEffect(() => {
    const childDataWithType = childData.map((obj) => ({
      price: obj.ad_hoc_rate ? obj.ad_hoc_rate : obj.rate,
      child_age: obj.age,
      adhoc_price: obj.ad_hoc_rate,
      pax_type: "child",
      rate: obj.rate,
      extra_bed_occupied: obj.extra_bed_occupied
        ? obj.extra_bed_occupied
        : false,
    }));
    const extraAdultDataWithType = extraAdultData.map((obj) => ({
      price: obj.ad_hoc_rate ? obj.ad_hoc_rate : obj.rate,
      adhoc_price: obj.ad_hoc_rate,
      pax_type: "adult",
      rate: obj.rate,
    }));
    const mergedArray = childDataWithType.concat(extraAdultDataWithType);
    setBookingDataArray((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        booking_paxes: mergedArray,
      };
      return updatedData;
    });
  }, [childData, extraAdultData, index, setBookingDataArray]);

  // fetch room plans and  updating the states for extra adult and child with bed

  useEffect(() => {
    setExtraAdultData((prevData) =>
      prevData.map((item) => ({
        ...item,
        rate: additionalBookingDetails.extra_adult,
      }))
    );
  }, [additionalBookingDetails.extra_adult]);

  useEffect(() => {
    setChildData((prevData) =>
      prevData.map((item) => ({
        ...item,
        rate: additionalBookingDetails.child_with_Bed,
      }))
    );
  }, [additionalBookingDetails.child_with_Bed]);

  // decide tax rate
  useEffect(() => {
    let newTaxRate = 0;

    if (+additionalBookingDetails.rate_bar_price < 7500) {
      newTaxRate = 12;
    } else if (+additionalBookingDetails.rate_bar_price >= 7500) {
      newTaxRate = 18;
    }

    if (adHocRoom !== "" && +adHocRoom < 7500) {
      newTaxRate = 12;
    } else if (adHocRoom >= 7500) {
      newTaxRate = 18;
    }

    setTaxRate(newTaxRate);

    setBookingDataArray((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        tax_rate: newTaxRate,
      };
      return updatedData;
    });
  }, [
    additionalBookingDetails.rate_bar_price,
    adHocRoom,
    index,
    setBookingDataArray,
  ]);

  //main calculations

  useEffect(() => {
    // ---------------------calculating total amount for extra adult with condition for ad-hoc---------------
    const totalExtraAdultRate = extraAdultData.reduce((acc, item) => {
      return acc + (item.ad_hoc_rate ? +item.ad_hoc_rate : +item.rate);
    }, 0);

    // ----------calculating total amount for extra adult with condition for ad-hoc-----------------------
    const totalChidRate = childData.reduce((acc, item) => {
      return acc + (item.ad_hoc_rate ? +item.ad_hoc_rate : +item.rate);
    }, 0);

    // ---------------------calculation for discount amount------------------
    let discountAmount =
      singleBookingData?.discount_type?.discount_type === "fixed"
        ? +singleBookingData?.discount_type?.discount_amount
        : (+additionalBookingDetails.rate_bar_price *
            +singleBookingData?.discount_type?.discount_amount) /
          100;

    if (isNaN(discountAmount)) {
      discountAmount = 0;
    }

    // ---------------------calculate discount Amount tac----------------------
    let tacDiscountAmount =
      ((+additionalBookingDetails.rate_bar_price - discountAmount) *
        +singleBookingData?.tac_discount) /
      100;

    if (isNaN(tacDiscountAmount)) {
      tacDiscountAmount = 0;
    }
    // console.log(tacDiscountAmount, "tacDiscountAmount")

    // console.log(tacDiscountAmount,"tacDiscountAmount")

    if (adHocRoom !== "") {
      discountAmount = 0;
      tacDiscountAmount = 0;
    }
    // console.log(discountAmount,"discountAmount")

    // ---------- Total Discount Amount -------------------------------
    const totalDiscountAmount = discountAmount || tacDiscountAmount; // apply single discount on booking

    // console.log(totalDiscountAmount,"totalDiscountAmount")

    // ---------------Total Room Rate Per Night Condition for ad----------------

    let roomPerNightConditionForAdhoc = singleBookingData.ad_hoc_rate
      ? +singleBookingData.ad_hoc_rate
      : +additionalBookingDetails.rate_bar_price;

    if (showAdHocRoomRate === false) {
      roomPerNightConditionForAdhoc = +additionalBookingDetails.rate_bar_price;
    }

    // console.log(roomPerNightConditionForAdhoc, "roomPerNightConditionForAdhoc")

    if (singleBookingData.tax_type === "exclusive") {
      // -----------condition for setting total room rate per night starts here------------
      let totalRoomRatePerNight =
        roomPerNightConditionForAdhoc - totalDiscountAmount;

      // console.log(totalRoomRatePerNight, "totalRoomRatePerNight")

      // -------condition for setting total room rate per night Ends here---------------

      // const totalRomRatePerNightWithExtraAdultAndChild = totalRoomRatePerNight + totalExtraAdultRate + totalChidRate
      const subTotalForExclusive =
        totalRoomRatePerNight *
          +singleBookingData.no_of_nights *
          +singleBookingData.no_of_rooms +
        (totalExtraAdultRate + totalChidRate) * +singleBookingData.no_of_nights;
      setSubTotal(subTotalForExclusive);
      const taxAmount = (subTotalForExclusive * +taxRate) / 100;

      const grandTotalForExclusive = subTotalForExclusive + taxAmount;

      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          grandTotal: grandTotalForExclusive,
          taxAmount: taxAmount,
          tax_rate: taxRate,
          subTotal: subTotalForExclusive,
          discountAmount:
            totalDiscountAmount *
            +singleBookingData.no_of_rooms *
            +singleBookingData.no_of_nights,
        };
        return updatedData;
      });
    } else if (singleBookingData.tax_type === "inclusive") {
      // -----------condition for setting total room rate per night starts here------------

      let totalRoomRatePerNightInclusive;
      if (
        adHocRoom !== "" &&
        +adHocRoom < +additionalBookingDetails.rate_bar_price
      ) {
        totalRoomRatePerNightInclusive =
          roomPerNightConditionForAdhoc - tacDiscountAmount;
      } else {
        totalRoomRatePerNightInclusive =
          roomPerNightConditionForAdhoc - totalDiscountAmount;
      }

      // -------condition for setting total room rate per night Ends here---------------

      const grandTotalForInclusive =
        totalRoomRatePerNightInclusive *
          +singleBookingData.no_of_nights *
          +singleBookingData.no_of_rooms +
        (totalExtraAdultRate + totalChidRate) * +singleBookingData.no_of_nights;

      // console.log(grandTotalForInclusive ,"grandTotalForInclusive")

      const taxAmountInclusive =
        (grandTotalForInclusive * +taxRate) / (100 + +taxRate);

      const subtotalInclusive =
        (grandTotalForInclusive * 100) / (100 + +taxRate);
      setSubTotal(subtotalInclusive);
      setBookingDataArray((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          grandTotal: grandTotalForInclusive,
          taxAmount: taxAmountInclusive,
          tax_rate: taxRate,
          subTotal: subtotalInclusive,
          discountAmount:
            totalDiscountAmount *
            +singleBookingData.no_of_rooms *
            +singleBookingData.no_of_nights,
        };
        return updatedData;
      });
    } else {
      return;
    }
  }, [
    extraAdultData,
    childData,
    adHocRoom,
    additionalBookingDetails,
    singleBookingData.tax_type,
    singleBookingData.discount_type?.discount_amount,
    singleBookingData.ad_hoc_rate,
    singleBookingData.rate_bar_price,
    singleBookingData.tac_discount,
    singleBookingData.no_of_rooms,
    singleBookingData.no_of_nights,
    singleBookingData.tax_rate,
    taxRate,
    showAdHocRoomRate,
    setSubTotal,
    setBookingDataArray,

    index,
  ]);

  useEffect(() => {
    setBookingDataArray((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        discount_type: discount_type,
      };
      return updatedData;
    });
  }, [discount_type, setBookingDataArray, index]);

  //  console.log("bookingDataArray",singleBookingData)

  return (
    <>
      <div className="create_booking_page_bottom align_center">
        <div className="flex align_center create_booking_page_bottom_head">
          <h5>{singleBookingData.room_category}</h5>
          <div style={{ fontSize: "12px" }}>
            <b> Available Rooms:</b>{" "}
            {singleBookingData?.limits?.available_rooms}, <b>Base Occupancy:</b>{" "}
            {singleBookingData?.limits?.base_adult}, <b>Max Occupancy :</b>{" "}
            {singleBookingData?.limits?.max_adult}, <b>Extra Adult Allowed:</b>{" "}
            {singleBookingData?.limits?.allowedExtraAdult},{" "}
            <b>Child Allowed:</b> {singleBookingData?.limits?.max_child}
          </div>
          <button
            className="create_booking_page_card_bottom"
            onClick={() => {
              handleDeleteBookingCard(index);
            }}
          >
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2001 3.2H12.6001C13.0419 3.2 13.4001 3.55817 13.4001 4L13.4001 4.8H0.600098L0.600119 4C0.600119 3.55817 0.958291 3.2 1.40012 3.2H3.80012V1.2C3.80012 0.537258 4.33738 0 5.00012 0H9.00012C9.66286 0 10.2001 0.537259 10.2001 1.2V3.2ZM5.40012 1.6V3.2H8.60012V1.6H5.40012Z"
                fill="#E03838"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.40012 6.4H12.6001V14.8C12.6001 15.4627 12.0629 16 11.4001 16H2.60012C1.93738 16 1.40012 15.4627 1.40012 14.8V6.4ZM4.6001 8.8H3.0001V13.6H4.6001V8.8ZM7.8001 8.8H6.2001V13.6H7.8001V8.8ZM9.4001 8.8H11.0001V13.6H9.4001V8.8Z"
                fill="#E03838"
              />
            </svg>
          </button>
        </div>

        <div
          className="flex gap create_booking_page_room_plan "
          style={{ alignItems: "flex-end", padding: "10px ", flexWrap: "wrap" }}
        >
          <div className="create_booking_selectBox_container">
            <label className="Create_booking_label">Room Plan</label>
            <select
              className="Create_booking_selectBox"
              value={singleBookingData.selected_meal_plan_id}
              onChange={(e) => handleRoomPlanChange(e, index)}
            >
              <option>Select</option>
              {singleBookingData?.room_plans?.length > 0 &&
                singleBookingData?.room_plans?.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item.id}
                      selected={+singleBookingData?.mealplan_id === +item.id}
                    >
                      {item.rate_plan_name
                        .replace(/Complimentary/g, "")
                        .replace(/\+/g, "with ")}
                    </option>
                  );
                })}
            </select>
          </div>

          {/* input for room per night   */}

          <div className="create_booking_editable_input_container">
            <label className="Create_booking_label_small">Rate/Night</label>
            <div className="common_min_input d_flex gap_7">
              <input
                defaultValue={additionalBookingDetails?.rate_bar_price}
                disabled
              ></input>
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleShowAdhocRates(index)}
                style={{ cursor: "pointer" }}
              >
                <g clipPath="url(#clip0_5205_24822)">
                  <path
                    d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                    fill="#888888"
                  />
                  <path
                    d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                    fill="#888888"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5205_24822">
                    <rect
                      width="10"
                      height="10.4167"
                      fill="white"
                      transform="translate(0.980072 0.291687)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          {/* input for add hoc price per night   */}

          {showAdHocRoomRate && (
            <div className="create_booking_selectBox_container">
              <label className="Create_booking_label_small">Ad hoc Rate</label>
              <input
                style={{ width: "70px" }}
                className="common_input"
                name="ad_hoc_rate"
                value={adHocRoom}
                onChange={(e) => handleAdoRatePerNightChange(e, index)}
              />
            </div>
          )}

          {/* number of rooms */}
          <CreateBookingEditableInput
            scale="large"
            value={singleBookingData.no_of_rooms}
            label="No. of Rooms"
            name="no_of_rooms"
            onChange={(e) => handleRoomsChange(e, index)}
            error={errors.no_of_rooms}
            // onFocus={() => handleRoomsChangeFocus(index)}
          />

          {/* number of adults */}
          <CreateBookingEditableInput
            scale="large"
            value={singleBookingData.no_of_adults}
            label="No. of Adults"
            name="no_of_adults"
            onChange={(e) => handleNumberOfAdultsChange(e, index)}
            error={errors.no_of_adults}
            onFocus={() => handleNumberOfAdultsChangeFocus(index)}
            disabled={singleBookingData.no_of_rooms === ""}
          />

          {/* number of adults */}

          <CreateBookingEditableInput
            scale="large"
            value={singleBookingData.extra_adults}
            label="Extra Adults"
            name={"extra_adults"}
            onChange={(e) => handleCommonExtraAdultChange(e, index)}
            // onFocus={() => handleCommonExtraAdultChangeFocus(index)}
            disabled={singleBookingData.no_of_adults === ""}
            error={errors.extra_adults}
          />
          <CreateBookingEditableInput
            scale="large"
            value={singleBookingData.no_of_child}
            label="No. of Child"
            name={"no_of_child"}
            onChange={(e) => handleChildInputChange(e, index)}
            disabled={singleBookingData.no_of_adults === ""}
            error={errors.no_of_child}
          />

          {/* {singleBookingData?.discount_type?.discount_type === "percent" ? (
            <>
              <div>
                <label className="common_label">Disc.%</label>
                <div className="common_min_input flex">
                  <input
                    disabled
                    value={
                      singleBookingData?.discount_type?.discount_amount || "0"
                    }
                  />
                </div>
              </div>
             
            </>
          ) : (
            <>
              <div>
                <label className="common_label">Disc. Amt</label>
                <div className="common_min_input flex">
                  <input
                    disabled
                    value={singleBookingData?.discountAmount || "0"}
                  />
                </div>
              </div>
            </>
          )}


{singleBookingData?.tac_discount ? (
                <>
                  <div>
                    <label className="common_label">Tac. Disc</label>
                    <div className="common_min_input flex">
                      <input disabled value={singleBookingData?.tac_discount} />
                    </div>
                  </div>
                </>
              ) : null} */}
          {discount_type ? (
            <>
              {singleBookingData?.discount_type?.discount_type === "percent" ? (
                <div>
                  <label className="common_label">Disc.%</label>
                  <div className="common_min_input flex">
                    <input
                      disabled
                      value={
                        singleBookingData?.discount_type?.discount_amount || "0"
                      }
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <label className="common_label">Disc. Amt</label>
                  <div className="common_min_input flex">
                    <input
                      disabled
                      value={singleBookingData?.discountAmount || "0"}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {singleBookingData?.tac_discount ? (
                <>
                  <div>
                    <label className="common_label">Tac. Disc</label>
                    <div className="common_min_input flex">
                      <input disabled value={singleBookingData?.tac_discount} />
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}

          <div>
            <label className="common_label">Subtotal</label>
            <div className="common_min_input flex">
              <input
                style={{ width: "max-content" }}
                value={subTotal ? subTotal?.toFixed(2) : 0}
                disabled
              ></input>
            </div>
          </div>
          <div className="create_booking_editable_input_container">
            <label className="Create_booking_label">Tax Rate</label>
            <div className="common_min_input d_flex gap_7">
              <input value={taxRate} disabled={true}></input>
            </div>
          </div>

          <div>
            <label className="common_label">Grandtotal</label>
            <div className="common_min_input flex">
              <input
                value={
                  singleBookingData?.grandTotal
                    ? singleBookingData?.grandTotal?.toFixed(2)
                    : 0
                }
                disabled
                style={{ width: "max-content" }}
              ></input>
            </div>
          </div>
        </div>
        <div className="create_booking_page_adult_details_row flex gap_7 align_center  new_createBooking_card_extra_adult_child_container_scroll">
          {/* extra adult */}

          {extraAdultData?.map((item, indexExtraAdult) => (
            <React.Fragment key={indexExtraAdult}>
              <div
                className="flex gap_7 create_booking_page_adult_details_box2"
                style={{ padding: "10px  " }}
              >
                <label className="adlut_label">Extra Adult</label>
                <div className="d_flex gap_7">
                  <label className="Create_booking_label_small">Rate</label>
                  <div className="rate_input_small d_flex extra_adults_new_input">
                    <input
                      name="rate"
                      defaultValue={item.rate}
                      className="common_input extra_adults_new_input"
                      disabled={true}
                    ></input>
                    <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleShowAddHocAdult(indexExtraAdult)}
                      style={{ cursor: "pointer" }}
                    >
                      <g clipPath="url(#clip0_5205_24822)">
                        <path
                          d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                          fill="#888888"
                        />
                        <path
                          d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                          fill="#888888"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5205_24822">
                          <rect
                            width="10"
                            height="10.4167"
                            fill="white"
                            transform="translate(0.980072 0.291687)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                {activeIndexAdults[indexExtraAdult] && (
                  <CreateBookingInputBox
                    alignment="horizontal"
                    label="Ad hoc Rate"
                    name={"ad_hoc_rate"}
                    value={item.ad_hoc_rate}
                    onChange={(e) => handleExtraAdultChange(e, indexExtraAdult)}
                  />
                )}
              </div>
            </React.Fragment>
          ))}

          {/* extra child */}

          {childData.map((item, indexChild) => (
            <React.Fragment key={indexChild}>
              <div className="create_booking_page_adult_details_box2">
                <div className="flex gap_7 adult_deatils_top">
                  <label className="adlut_label">Child {indexChild + 1}</label>
                  <div className="gap_7 flex extra_adults_new_input">
                    <label className="Create_booking_label_small">Age</label>
                    <input
                      onChange={(e) => handleChildAgeChange(e, indexChild)}
                      value={item.age || ""}
                      type="number"
                      name="age"
                      className="adlut_box_input"
                    ></input>
                  </div>
                  <div className="d_flex gap_7">
                    <label className="Create_booking_label_small">Rate</label>
                    <div className="rate_input_small d_flex extra_adults_new_input">
                      <input
                        value={item.rate}
                        type="number"
                        name="rate"
                        className="adlut_box_input"
                        disabled
                      ></input>
                      <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleShowAddHocChild(indexChild)}
                        style={{ cursor: "pointer" }}
                      >
                        <g clipPath="url(#clip0_5205_24822)">
                          <path
                            d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                            fill="#888888"
                          />
                          <path
                            d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                            fill="#888888"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5205_24822">
                            <rect
                              width="10"
                              height="10.4167"
                              fill="white"
                              transform="translate(0.980072 0.291687)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  {activeIndexChild[indexChild] && (
                    <div
                      className="create_reservation_vertical_inputs_container d_flex extra_adults_new_input"
                      style={{ display: "flex", gap: "5px", width: "130px" }}
                    >
                      <label className="Create_booking_label_small">
                        {" "}
                        Ad.hoc. Rate
                      </label>
                      <input
                        onChange={(e) => handleChildAdHocRate(e, indexChild)}
                        value={item.ad_hoc_rate}
                        name={"ad_hoc_rate"}
                        type="number"
                      />
                    </div>
                  )}
                </div>

                {!(item.age < 6) && item.age !== "" && (
                  <div className="flex gap_7">
                    <label className="Create_booking_label_small flex gap_7">
                      <input
                        type="radio"
                        name={`childBed_${indexChild}_${index}`} // Unique name for each child's radio button group
                        value={
                          singleBookingData.room_rate_details.child_with_Bed
                        }
                        onChange={(e) => handleChildRadioButtons(e, indexChild)}
                        checked={
                          +singleBookingData.room_rate_details
                            .child_with_Bed === +item.rate
                        }
                      />
                      With Bed
                    </label>
                    <label className="Create_booking_label_small flex gap_7">
                      <input
                        type="radio"
                        name={`childBed_${indexChild}_${index}`} // Unique name for each child's radio button group
                        value={
                          singleBookingData.room_rate_details.child_without_Bed
                        }
                        onChange={(e) => handleChildRadioButtons(e, indexChild)}
                        checked={
                          +singleBookingData.room_rate_details
                            .child_without_Bed === +item.rate
                        }
                      />
                      Without Bed
                    </label>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default AgentHoldRoomsCreateBookingCard;
