import React, { useEffect, useState } from "react";
import { Icon } from "@shopify/polaris";
import { getBaseUrl } from "../../utils/utils";
import { getDecryptedData } from "../../utils/encryptStorage";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import HandleNaN from "../CustomComponents/HandleNaN/HandleNaN";
import RoundOffNumber from "../CustomComponents/RoundOffNumber/RoundOffNumber";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { CancelMajor } from "@shopify/polaris-icons";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";

const AddFolioChargesPopup = ({
  setAddChargesPopup,
  property_id,
  folioData,

  propertyCurrentDate,
  currentTimeToBackend,
  fetchFolioInfo,
  submitFunction
}) => {
  const [addChargesData, setAddChargesData] = useState({
    charge_type: "",
    description: "",
    rate: "",
    quantity: "",
    net_total: "",
    subtotal: "",
    discount_amount: "",
    gst_type: "inclusive",
    discount_rate: "",
    tax_rate: "",
    tax_amount: "",
  });
  const [roundedValue, setRoundedValue] = useState(0);
  const [roundOffValue, setRoundOffValue] = useState(null);

  const baseUrl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "5%", value: "5" },
    { label: "12%", value: "12" },
    { label: "18%", value: "18" },
  ];
  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const serviceOptions = [
    { label: "Select Option", value: "" },
    { label: "Room-Charge", value: "Room-Charge" },
    { label: "Food-Charge", value: "Food-Charge" },
    { label: "Housekeeping-Charge ", value: "Housekeeping-Charge" },
    { label: "Miscellaneous-Charge", value: "Miscellaneous-Charge" },
    { label: "Laundry-Charge", value: "Laundry-Charge" },
    // { label: "MISC", value: "misc" },
  ];

  const isDecimal = addChargesData?.net_total % 1 !== 0;

  const handleSubmitCreateFolio = (event) => {
    event.preventDefault();
    fetch(`${baseUrl}/api/v1/add/folios/charges/${property_id?.id}`, {
      method: "POST",
      body: JSON.stringify({
        ...addChargesData,
        folio_id: folioData.id,
        net_total: roundedValue ? roundedValue : addChargesData.net_total,
        round_off: roundOffValue,
        property_current_date: propertyCurrentDate + " " + currentTimeToBackend,
        date: propertyCurrentDate,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesPopup(false);
          fetchFolioInfo(property_id?.id);
          setAddChargesData({
            charge_type: "",
            description: "",
            rate: "",
            quantity: "",
            net_total: "",
            subtotal: "",
            discount_amount: "",
            gst_type: "inclusive",
            discount_rate: "",
            tax_rate: "",
            tax_amount: "",
          });
          window.location.reload();

          // checkFolioSummery(
          //   userToken,
          //   folioData?.id,
          //   property_id?.id,
          //   chargeAddedTransactions,
          //   true
          // );
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const handleRoundOffChange = (rounded, roundOffValue) => {
    setRoundedValue(rounded);
    setRoundOffValue(roundOffValue);
  };

  const setAddChargesHandleChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "number" && parseFloat(value) < 0) {
      return showErrorToast("Amount should be greater than 0");
    }
    if (name === "discount_rate" && parseFloat(value) > 100) {
      return showErrorToast("Discount should be less than 100");
    }

    setAddChargesData((prevData) => ({
      ...prevData,
      [name]: value,
      // Update net_total based on the roundedValue if available
      net_total: roundedValue ? roundedValue : prevData.net_total,
    }));
  };

  useEffect(() => {
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;

      let totalAmount = priceAfterDiscount + gstAmount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);

      // GST Inclusive Price * 100/(100 + GST Rate Percentage)
      subtotal = (priceAfterDiscount * 100) / (100 + tax_rate);

      let totalAmount = priceAfterDiscount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    }
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  return (
    <div className="bg-backdrop">
      <form
        onSubmit={ submitFunction ? (event) =>submitFunction(event , addChargesData, setAddChargesData ) : handleSubmitCreateFolio}
        style={{ width: "900px" }}
        className="stock-add-model-container"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div className="add-ota-model-container-heading">Add Charges</div>
          <div
            onClick={() => [
              setAddChargesPopup(false),
              setAddChargesData({}),
              setRoundOffValue(0),
              setRoundedValue(0),
            ]}
            style={{ cursor: "pointer" }}
          >
            <Icon source={CancelMajor} color="base" />
          </div>
        </div>
        <div className="form-container-div-full flex flex_gap_10">
          <div className="form-container-div" style={{ width: "25%" }}>
            <VerticalInputSelectCustomCopy
              options={serviceOptions}
              titleName="Service"
              name="charge_type"
              value={addChargesData.charge_type}
              onChange={setAddChargesHandleChange}
            />
          </div>
          <div className="form-container-div" style={{ width: "25%" }}>
            <VerticalInputFieldCustom
              required={true}
              titleName="Description"
              name="description"
              type="text"
              value={addChargesData.description}
              onChange={setAddChargesHandleChange}
            />
          </div>
          <div className="form-container-div" style={{ width: "25%" }}>
            <VerticalInputFieldCustom
              required={true}
              titleName="Rate"
              name="rate"
              type="number"
              value={addChargesData.rate}
              onChange={setAddChargesHandleChange}
            />
          </div>
          <div className="form-container-div" style={{ width: "25%" }}>
            <VerticalInputFieldCustom
              required={true}
              titleName="Qty"
              name="quantity"
              type="number"
              value={addChargesData.quantity}
              onChange={setAddChargesHandleChange}
            />
          </div>
        </div>

        <div className="form-container-div-full flex flex_gap_10">
          <div className="form-container-div" style={{ width: "25%" }}>
            <VerticalInputFieldCustom
              required={true}
              titleName="Discount%"
              name="discount_rate"
              type="number"
              value={addChargesData.discount_rate}
              onChange={setAddChargesHandleChange}
            />
          </div>

          <div className="form-container-div" style={{ width: "25%" }}>
            <VerticalInputSelectCustomCopy
              options={gstTypeOptions}
              required={true}
              titleName="GST Type"
              name="gst_type"
              value={addChargesData.gst_type}
              onChange={setAddChargesHandleChange}
            />
          </div>
          <div className="form-container-div" style={{ width: "25%" }}>
            <VerticalInputSelectCustomCopy
              required={true}
              options={gstOptions}
              titleName="GST%"
              name="tax_rate"
              value={addChargesData.tax_rate}
              onChange={setAddChargesHandleChange}
            />
          </div>
          <div className="form-container-div" style={{ width: "25%" }}>
            <VerticalInputFieldCustom
              required={true}
              titleName="Subtotal"
              name="sub_total"
              disabled={true}
              type="number"
              value={addChargesData.subtotal}
              onChange={setAddChargesHandleChange}
            />
          </div>
        </div>

        {/* <div>
        Subtotal :{" "}
        {addChargesData.gst_type === "inclusive" ? (
          // <HandleNaN
          //   value={addChargesData.subtotal - addChargesData.tax_amount}
          // />
          <HandleNaN value={addChargesData.subtotal} />
        ) : (
          <HandleNaN value={addChargesData.subtotal} />
        )}
      </div> */}

        <div className="flex justify_content_between align_items_center">
          {/* Buttons */}
          <div>
            <div className="flex flex_gap_10">
              <BgThemeButton children={"Add"} type={"submit"} />
              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => setAddChargesPopup(false)}
              />
            </div>
          </div>
          {/* Total And Tax Amount */}
          <div className="flex align_items_end flex_gap_5">
            <div className="form-container-div-full flex flex_gap_10">
              {/* <div
              className="form-container-div"
              style={{ width: "20%" }}
            > */}
              <VerticalInputFieldCustom
                required={true}
                style={{ margin: "0" }}
                titleName={"Total"}
                name="net_total"
                type="number"
                disabled={true}
                // value={
                //   addChargesData.gst_type === "inclusive"
                //     ? // ? addChargesData.net_total - addChargesData.tax_amount
                //     addChargesData.net_total
                //     : addChargesData.net_total
                // }

                value={roundedValue ? roundedValue : addChargesData.net_total}
                onChange={setAddChargesHandleChange}
              />
              {/* </div> */}

              {!isNaN(addChargesData.net_total) && isDecimal && (
                <div style={{ width: "100%" }}>
                  <div className="flex flex_gap_10">
                    <div>Round Off</div>
                  </div>
                  <div style={{ height: "36px" }}>
                    <RoundOffNumber
                      totalAmount={addChargesData?.net_total}
                      // taxRate={taxrate}
                      onRoundOffChange={handleRoundOffChange}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="form-container-div-full folio_tax_amount_sm">
              Tax Amount : <HandleNaN value={addChargesData.tax_amount} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddFolioChargesPopup;
